<template>
  <div class="cart-item">
    <v-list-item
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      class="d-flex flex-row flex-wrap justify-space-between align-center"
      :class="isInPromo ? promoClass : ''"
      @click="updatedDrawerRight(false)"
    >
      <div
        v-if="
          product.warehousePromo &&
            product.warehousePromo.view &&
            product.warehousePromo.view.bubble &&
            isInPromo
        "
        class="promo_bubble"
        v-html="item.product.warehousePromo.view.bubble"
      ></div>
      <img
        width="70"
        height="70"
        :src="item.product.mediaURL"
        :alt="item.product.name"
        onerror="this.onerror=null;this.src='/no-icon.png'"
        class="my-0"
      />
      <v-list-item-content class="flex-grow px-1">
        <div class="text-body-2">
          {{ item.product.name }}
        </div>
        <v-list-item-subtitle
          class="font-weight-bold text-uppercase text-body-2"
        >
          {{ item.product.shortDescr }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-uppercase">
          {{ item.product.description }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.cartItemInfo.size"
          class="cart-item-info"
        >
          <i>{{ $t("product.preferences") }}: {{ item.cartItemInfo.size }}</i>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          v-if="
            itemAdjustment &&
              itemAdjustment.userGiftCertificateId &&
              itemAdjustment.giftCertificate
          "
          class="cart-item-info promo--text"
        >
          <em>{{ itemAdjustment.giftCertificate.name }}: </em>
          <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action class="d-flex flex-row align-center ml-0 ml-sm-2">
        <div class="promo-wrapper-product-card">
          <div
            v-if="item.product.warehousePromo && isInPromo"
            class="product_promo"
            :class="product.warehousePromo.view.cssClass"
          >
            <div
              class="promo_header"
              v-html="item.product.warehousePromo.view.header"
            ></div>
            <div
              class="promo_body"
              v-html="item.product.warehousePromo.view.body"
            ></div>
            <div
              class="promo_footer"
              v-html="item.product.warehousePromo.view.footer"
            ></div>
          </div>
        </div>
        <div
          v-if="item.editable"
          class="d-flex align-center w-100 gap-1 flex-wrap"
          :class="
            $vuetify.breakpoint.xs ? 'justify-end' : 'justify-space-between'
          "
        >
          <ProductPrice
            v-if="full"
            :product="item.product"
            :showStandardPrice="isInPromo"
          />
          <ProductQty :item="item" :product="item.product" :updateCart="true" />
          <div>
            <div class="text-left text-uppercase d-block d-sm-none">
              {{ $t("cartSummary.grossTotal") }}
            </div>
            <div class="price font-weight-bold">
              {{ $n(item.grossTotal, "currency") }}
            </div>
          </div>
          <v-btn
            class="remove-from-card-btn"
            icon
            aria-label="Rimuovi dal carrello"
            @click.stop.prevent="remove"
            @mousedown.stop
            v-if="
              !(
                item.product.productInfos &&
                item.product.productInfos.USER_DISABLED == '1'
              )
            "
          >
            <v-icon>$delete</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>
    <v-checkbox
      v-if="full && item.editable"
      dense
      color="primary"
      hide-details
      class="accept-alternatives my-1 pt-0 px-4 px-md-2"
      v-model="acceptAlternative"
    >
      <template v-slot:label>
        <span>{{ $t("cartDetail.acceptAlternative") }}</span>
      </template>
    </v-checkbox>

    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <!-- <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div> -->
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn outlined text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            class="primary white--text"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
#checkout-items-list {
  .v-list-item {
    padding: 0;
  }
}
#cart-right-drawer {
  .v-list-item {
    padding: 0 8px;
  }
}
.cart-item {
  .v-list-item::after {
    min-height: 0;
  }
  .price {
    min-width: 75px;
    font-size: 20px;
    width: 75px;
  }
  .promo-wrapper-product-card {
    position: initial;
    .product_promo {
      margin-right: 4px;
      padding: 2px;
      position: relative;
    }
  }
  .old-price {
    font-size: 14px !important;
  }
  .cur-price {
    font-size: 18px !important;
  }
  .product-qty {
    width: 160px;
    min-width: 160px;
    .qty-wrap.not-empty .minus {
      background-color: $white !important;
    }
    .qty-wrap.not-empty .plus {
      background-color: $white !important;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-list-item__action {
      width: 100%;
    }
  }
  .v-list-item.v-list-item--active.v-list-item--link.theme--light:before {
    opacity: 0;
  }
}
.is-cordova {
  .cart-item {
    .product-qty {
      margin: 0px 8px;
    }
  }
}
@media (max-width: 400px) {
  .cart-item {
    .remove-from-card-btn {
      position: absolute;
      top: 12px;
      right: 4px;
    }
    .v-list-item__content {
      margin-right: 38px;
    }
  }
}
</style>
<script>
import ProductQty from "@/components/product/ProductQty.vue";
import productMixin from "~/mixins/product";
import get from "lodash/get";

import { mapActions, mapGetters } from "vuex";

import {
  isOnlyCharacterAndNumber,
  maxLength
} from "~/validator/validationRules";
import ProductPrice from "../product/ProductPrice.vue";

export default {
  name: "CartItem",
  mixins: [productMixin],
  components: {
    ProductQty,
    ProductPrice
  },
  props: {
    full: { type: Boolean, default: false },
    item: { type: Object, required: true }
  },
  data() {
    return {
      removeDialog: false,
      notesRule: [
        isOnlyCharacterAndNumber(
          "Sono ammessi solamente caratteri alfanumerici."
        ),
        maxLength(200, "max 200 caratteri")
      ]
    };
  },
  computed: {
    product() {
      return this.item.product;
    },
    isInPromo() {
      // show also warehousePromoTypeId 4 because is about load points and doesn't apply a discount on price
      return (
        this.item.discount > 0 ||
        (this.item.product.warehousePromo &&
          this.item.product.warehousePromo.warehousePromoTypeId == 4)
      );
    },
    itemAdjustment() {
      return this.getOrderAdjustmentByItemId(this.item.itemId);
    },
    ...mapGetters({
      getOrderAdjustmentByItemId: "cart/getOrderAdjustmentByItemId"
    }),
    acceptAlternative: {
      get() {
        return get(this.item, "cartItemInfo.accept_alternatives") == "true";
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    userNotes: {
      get() {
        return this.item.cartItemInfo.user_notes;
      },
      set(value) {
        this.tempUserNotes = value;
      }
    }
  },
  methods: {
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    }
  }
};
</script>
