var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-item"},[_c('v-list-item',{staticClass:"d-flex flex-row flex-wrap justify-space-between align-center",class:_vm.isInPromo ? _vm.promoClass : '',attrs:{"to":{
      name: 'Product',
      params: { slug: _vm.item.product.slug }
    }},on:{"click":function($event){return _vm.updatedDrawerRight(false)}}},[(
        _vm.product.warehousePromo &&
          _vm.product.warehousePromo.view &&
          _vm.product.warehousePromo.view.bubble &&
          _vm.isInPromo
      )?_c('div',{staticClass:"promo_bubble",domProps:{"innerHTML":_vm._s(_vm.item.product.warehousePromo.view.bubble)}}):_vm._e(),_c('img',{staticClass:"my-0",attrs:{"width":"70","height":"70","src":_vm.item.product.mediaURL,"alt":_vm.item.product.name,"onerror":"this.onerror=null;this.src='/no-icon.png'"}}),_c('v-list-item-content',{staticClass:"flex-grow px-1"},[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"font-weight-bold text-uppercase text-body-2"},[_vm._v(" "+_vm._s(_vm.item.product.shortDescr)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.item.product.description)+" ")]),(_vm.item.cartItemInfo.size)?_c('v-list-item-subtitle',{staticClass:"cart-item-info"},[_c('i',[_vm._v(_vm._s(_vm.$t("product.preferences"))+": "+_vm._s(_vm.item.cartItemInfo.size))])]):_vm._e(),(
          _vm.itemAdjustment &&
            _vm.itemAdjustment.userGiftCertificateId &&
            _vm.itemAdjustment.giftCertificate
        )?_c('v-list-item-subtitle',{staticClass:"cart-item-info promo--text"},[_c('em',[_vm._v(_vm._s(_vm.itemAdjustment.giftCertificate.name)+": ")])]):_vm._e()],1),_c('v-list-item-action',{staticClass:"d-flex flex-row align-center ml-0 ml-sm-2"},[_c('div',{staticClass:"promo-wrapper-product-card"},[(_vm.item.product.warehousePromo && _vm.isInPromo)?_c('div',{staticClass:"product_promo",class:_vm.product.warehousePromo.view.cssClass},[_c('div',{staticClass:"promo_header",domProps:{"innerHTML":_vm._s(_vm.item.product.warehousePromo.view.header)}}),_c('div',{staticClass:"promo_body",domProps:{"innerHTML":_vm._s(_vm.item.product.warehousePromo.view.body)}}),_c('div',{staticClass:"promo_footer",domProps:{"innerHTML":_vm._s(_vm.item.product.warehousePromo.view.footer)}})]):_vm._e()]),(_vm.item.editable)?_c('div',{staticClass:"d-flex align-center w-100 gap-1 flex-wrap",class:_vm.$vuetify.breakpoint.xs ? 'justify-end' : 'justify-space-between'},[(_vm.full)?_c('ProductPrice',{attrs:{"product":_vm.item.product,"showStandardPrice":_vm.isInPromo}}):_vm._e(),_c('ProductQty',{attrs:{"item":_vm.item,"product":_vm.item.product,"updateCart":true}}),_c('div',[_c('div',{staticClass:"text-left text-uppercase d-block d-sm-none"},[_vm._v(" "+_vm._s(_vm.$t("cartSummary.grossTotal"))+" ")]),_c('div',{staticClass:"price font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")])]),(
            !(
              _vm.item.product.productInfos &&
              _vm.item.product.productInfos.USER_DISABLED == '1'
            )
          )?_c('v-btn',{staticClass:"remove-from-card-btn",attrs:{"icon":"","aria-label":"Rimuovi dal carrello"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.remove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e()],1):_vm._e()])],1),(_vm.full && _vm.item.editable)?_c('v-checkbox',{staticClass:"accept-alternatives my-1 pt-0 px-4 px-md-2",attrs:{"dense":"","color":"primary","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("cartDetail.acceptAlternative")))])]},proxy:true}],null,false,3666450440),model:{value:(_vm.acceptAlternative),callback:function ($$v) {_vm.acceptAlternative=$$v},expression:"acceptAlternative"}}):_vm._e(),_c('v-divider'),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"body-1 pt-8"},[_vm._v(" "+_vm._s(_vm.$t("message.remove", { name: this.product.name, quantity: this.quantity, unit: this.unit }))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{staticClass:"primary white--text",attrs:{"text":""},on:{"click":function($event){$event.preventDefault();return _vm.doRemove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" Si ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }