<template>
  <div>
    <div class="accept-replacement" v-if="full">
      <v-img
        alt="404"
        style="margin: 0 auto;"
        src="/img_layout/sostitutivi.png"
        contain
      />
      <div>
        <p>{{ $t("cartDetail.acceptAlternativesTitle") }}</p>
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="mr-5 mt-1" v-on="on">
                $info
              </v-icon>
            </template>
            {{ $t("cartDetail.acceptAlternativesTooltip") }}
          </v-tooltip>
          <v-checkbox
            hide-details
            @click.prevent
            :label="$t('cartDetail.acceptAlternatives')"
            v-model="acceptAlternatives"
            ><div slot="label">
              {{ $t("cartDetail.acceptAlternatives") }}
            </div>
          </v-checkbox>
        </div>
      </div>
    </div>
    <div id="cartItemList" flat subheader class="cart-item-list">
      <template>
        <v-subheader
          class="text-caption d-flex justify-space-between default--text"
          :class="full ? 'px-0' : 'px-2'"
        >
          <span v-html="$tc('cart.cartItemCount', cart.totalItems)" />
          <!-- <v-btn
            color="primary"
            plain
            class="empty-cart-btn d-md-none d-lg-none"
            @click="emptyCartConfirm"
            >{{ $t("cart.emptyCart") }}</v-btn
          > -->
          <v-switch
            v-model="showSectors"
            label="Dividi per categoria"
          ></v-switch>
        </v-subheader>
      </template>
      <v-list>
        <div
          v-if="!showSectors"
          class="no-sectors"
          :class="[
            { 'cart-item-list-no-checkout': !full },
            { 'cart-item-list-no-checkout-higher': !full }
          ]"
        >
          <transition-group name="bounce">
            <CartItem
              v-for="cartItem in cart.cartItems"
              :key="cartItem.itemId"
              :item="cartItem"
              :full="full"
              class="align-center px-md-2"
            />
          </transition-group>
        </div>
        <v-expansion-panels v-else v-model="panel" accordion flat multiple tile>
          <v-expansion-panel
            v-for="[categoryId, group] in itemGroups"
            :aria-controls="'panel-content-' + categoryId"
            role="button"
            :key="categoryId"
          >
            <v-expansion-panel-header
              :aria-controls="'panel-content-' + categoryId"
              role="button"
              :aria-expanded="panel.includes(categoryId) ? 'true' : 'false'"
              class="text-caption primary--text grey lighten-3 px-3"
            >
              <div class="d-flex align-center black--text">
                <span class="font-weight-bold mr-2">{{ group.name }}</span>
                <span
                  v-html="$tc('lists.products', group.products.length)"
                ></span>
              </div>
              <template v-slot:actions>
                <v-icon color="default" small>
                  {{
                    panel.includes(categoryId) ? "$chevronUp" : "$chevronDown"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content :id="'panel-content-' + categoryId">
              <transition-group name="bounce">
                <CartItem
                  class="sectorItem"
                  v-for="item in group.products"
                  :key="item.itemId"
                  :item="item"
                  :full="full"
                />
              </transition-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-list>
    </div>
  </div>
</template>
<style lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.cart-item-list {
  .v-expansion-panel {
    .v-expansion-panel-header {
      min-height: 20px;
      padding: 10px 24px;
    }
    .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 0px !important;
      .cart-item {
        min-height: 70px;
      }
    }
  }
  .checkout-item-list {
    .no-sectors {
      .v-list-item {
        min-height: 70px !important;
        padding: 8px 0px;
      }
    }
  }
  .v-subheader {
    height: auto;
    max-height: #{$list-subheader-min-height};
    background: $white;
  }
  .cart-item-list-no-checkout {
    min-height: 30vh;
    height: calc(
      100vh - (#{$cart-info-height} + #{$list-subheader-min-height}) - 80px
    );
    //padding-top: #{$list-subheader-min-height};
    //margin-top: -#{$list-subheader-min-height};
    padding-bottom: #{$summary-height};
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--v-grey-darken2);
      border-radius: 20px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .cart-item-list-no-checkout-higher {
    height: calc(
      100vh -
        (
          #{$cart-info-height} + #{$list-subheader-min-height} + #{$cartlist-diff}
        )
    ) !important;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .cart-item-list-no-checkout {
      min-height: auto;
      max-height: 100%;
    }
  }
}
.note-textarea {
  .v-input__slot {
    padding: 0 !important;
  }
}
</style>

<script>
import CartItem from "./CartItem.vue";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapState } from "vuex";
import {
  isOnlyCharacterAndNumber,
  maxLength
} from "~/validator/validationRules";
import analyticsService from "~/service/analyticsService";

export default {
  name: "CartItemList",
  components: { CartItem },
  props: {
    full: { type: Boolean, default: false },
    showCartButton: { type: Boolean, default: true }
  },
  data() {
    return {
      panel: [],
      tempUserNotes: null,
      showSectors: true,
      notesRule: [
        isOnlyCharacterAndNumber(
          "Sono ammessi solamente caratteri alfanumerici."
        ),
        maxLength(200, "max 200 caratteri")
      ]
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (cartItem.cartItemInfo.accept_alternatives != "true") {
            value = false;
          }
          return value;
        });
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
        analyticsService.trackEvent(null, "accept_alternatives", value);
      }
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        this.tempUserNotes = value;
        //update on blur
      }
    },
    itemGroups() {
      let groups = new Map();
      if (this.cart.cartItems) {
        this.cart.cartItems.forEach(item => {
          let category = this.$store.getters["category/lookupCategory"](
            item.product.categoryId
          );
          if (category === undefined || category === null) {
            category = {
              categoryId: -1,
              name: "Senza categoria"
            };
          }
          if (groups.has(category.categoryId)) {
            groups.get(category.categoryId).products.push(item);
          } else {
            groups.set(category.categoryId, {
              name: category.name,
              products: [item]
            });
          }
        });
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());
      return groups;
    }
  },
  methods: {
    handleRemove() {},
    changeTimeslot() {},
    changeDelivery() {},
    ...mapActions({
      // emptyCart: "cart/emptyCart",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    })
    // async emptyCartConfirm() {
    //   if (this.cart.totalItems > 0) {
    //     let res = await global.vm.$dialog.confirm({
    //       text: global.vm.$t("message.emptyCart")
    //     });
    //     if (res) {
    //       this.emptyCart();
    //     }
    //   }
    // }
  },
  created() {
    AnalyticsService.viewProducts(this.cart.cartItems, this.itemListName, 0);
  }
};
</script>
