<template>
  <v-card class="card-complete-profile mb-4" flat color="warning">
    <v-card-title>
      {{ $t("checkout.completeProfileTitle") }}
    </v-card-title>

    <v-card-text>
      {{ $t("checkout.completeProfileSubTitle") }}
    </v-card-text>

    <v-card-actions>
      <v-btn depressed color="white" :to="{ name: 'ProfileUpdate' }" block>
        {{ $t("checkout.completeProfileBtn") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.card-complete-profile {
}
</style>
<script>
export default {
  name: "CheckoutCompleteProfile"
};
</script>
